import './Footer.css'
import {useNavigate} from 'react-router-dom';

export default function Footer() {
  const navigation = useNavigate();
	

	return (
	<>	
    <footer className="bg-[#efefef] mt-10 flex justify-around p-1.5 gap-3">
      <div className="div-1 w-96">
        <div className="logo-div pt-3 pb-1 flex justify-center item-center">
          <img
            src="https://ik.imagekit.io/d3kzbpbila/thejashari_HkNJJQp9gd"
            alt="Dhana-Foods-Logo"
            className="Dhana-logo h-[100px] aspect-square cursor-pointer"
          />
        </div>
        <div className="my-2">
        <strong
        className="w-1/4 py-2 text-justify font-semibold text-[rgba(61,61,61,0.87)]"
        >
          At Dhana Foods, We are making value added items from traditional rice and millet, vegetable, leaves and flowers.
        </strong>
        </div>
      </div>
      {/*<div className="div-2 w-28 leading-loose flex justify-center flex-col">
              <p className="hover:text-blue-500" ><a href="">About Us</a></p>
              <p className="hover:text-blue-500" ><a href="">Offer</a></p>
              <p className="hover:text-blue-500" ><a href="">FAQS</a></p>
              <p className="hover:text-blue-500" ><a href="">Services</a></p>
              <p className="hover:text-blue-500" ><a href="">HelpCenter</a></p>
            </div>*/}
      <div className="div-3 w-[30%] leading-loose flex justify-center flex-col">
        <p className="text-lg mb-2" >Contact us</p>
        <p>
          <i className="fa-solid fa-phone pr-1" style={{color: "#000"}}></i>
          +91 9384331099
        </p>
        <p>
          <i className="fa-solid fa-envelope pr-1" style={{color: "#000"}}></i
          >dhanasfoodproduct@gmail.com
        </p>
        <p className="flex items-center text-sm" >
          <i className="fa-solid fa-location-dot pr-1" style={{color: "#000"}}></i>
          DHANA FOOD PRODUCTS B1, 3rd Avenue, Gladway city, Avaniyapuram, Madurai - 625012
        </p>
      </div>
      <div className="div-4 leading-loose flex justify-center flex-col w-52">
        <p className="hover:text-blue-500 cursor-pointer" onClick={()=>{
          navigation('/Products');
        }} >Shop</p>
        <p className="hover:text-blue-500 cursor-pointer" onClick={()=>{
          navigation('/Refund-Policy');
        }} >Refund Policy</p>
        <p className="hover:text-blue-500 cursor-pointer" onClick={()=>{
          navigation('/Shipping-Delivery');
        }}  >Shipping and Delivery</p>
        <p className="hover:text-blue-500 cursor-pointer" onClick={()=>{
          navigation('/Privacy-Policy');
        }} >Privacy Policy</p>
        <p className="hover:text-blue-500 cursor-pointer" onClick={()=>{
          navigation('/Terms-Conditions');
        }} >Terms and Conditons</p>
      </div>
      <div className="div-5 justify-center flex flex-col">
        
        <p className="text-lg ">Follow Us</p>
        <div className="social-icon-div flex gap-1 mt-1">
          <p>
            <a href="https://www.youtube.com/@dhanasfood">
              <i
                className="fa-brands fa-youtube cursor-pointer"
                style={{color: "#000"}}
              ></i>
            </a>
          </p>
          <p>
            <i
              className="fa-brands fa-facebook cursor-pointer"
              style={{color: "#000"}}
            ></i>
          </p>
          <p>
            <a
              href="https://www.linkedin.com/in/dhana-s-food-8a05ab285/?originalSubdomain=in"
            >
              <i
                className="fa-brands fa-linkedin cursor-pointer"
                style={{color: "#000"}}
              ></i>
            </a>
          </p>
          <p>
            <i
              className="fa-brands fa-x-twitter cursor-pointer"
              style={{color: "#000"}}
            ></i>
          </p>
        </div>
      </div>
    </footer>
    <div className="text-center my-1">
      <p className="text-center">
      	Copyright &copy; 2024 All Rights Reserved
      </p>
    </div>
    </>
	)
}