import logo from './logo.svg';
import Header from './components/Header';
import CarouselComponent from './components/CarouselComponent';
import SearchBar from './components/SearchBar';
import ClientsAndProducts from './components/ClientsAndProducts'; 
import Trending from './components/Trending'; 
import Categories from './components/Categories'
import Testimonials from './components/Testimonials';
import YoutubeChannel from './components/YoutubeChannel';
import Achievements from './components/Achievements';
import Footer from './components/Footer';
import { IoLogoWhatsapp } from "react-icons/io5";
import {useState,useEffect} from 'react';
import {getWebsiteRoute} from './utils/ApiRoutes';
import axios from 'axios';
import "./Loader.css";
import {useRecoilState} from 'recoil';
import {currentUserState} from './atoms/userAtom';
import {login} from './utils/ApiRoutes';
import {RxCross2} from 'react-icons/rx';

function App() {

  const [websiteDetails,setWebsiteDetails] = useState({});
  const [loading,setLoading] = useState(true);
  const [currentUser,setCurrentUser]  = useRecoilState(currentUserState);
  const [offerAlert,setOfferAlert] = useState(true);

  const fetchWebsite = async() => {
    const {data} = await axios.get(getWebsiteRoute);
    if(data?.status){
      setWebsiteDetails(data?.website);
      setLoading(false);
    }else{
      window.location.reload();
    }
  }

  const checkAuth = async() => {
    if(!currentUser && localStorage.getItem("Dhana-Foods-Auth")){
      const localData = localStorage.getItem("Dhana-Foods-Auth");
      const parsedData = JSON.parse(localData);
      const {data} = await axios.post(login,parsedData);
      if(data?.status) {
        setCurrentUser(data?.user);
      }
    }
  }

  useEffect(()=>{
    fetchWebsite();
    checkAuth();
  },[])

  return (
    <div className="App ">
        
        <div className={`fixed top-0 transition-all duration-200 ease-in-out bg-white flex items-center 
        justify-center ${loading ? 'left-0' : '-left-[100%]'} h-[100vh] w-full z-[60]`}>
          <div class="loader10">
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
          </div>
        </div>
        <div onClick={()=>setOfferAlert(false)} className={`fixed ${offerAlert ? 'bottom-0' : '-bottom-[100%]'} 
        transition-all duration-200 ease-in-out w-full left-0 flex items-center justify-center z-50`}>
          <div className=" mx-auto bg-red-500 border-[1px] border-b-[0px] border-gray-200 rounded-t-lg p-2">
            <h1 className="text-md text-white flex items-center gap-2">
              Free Delivery Charges (Limited Time Offer)
              <div className="p-1 rounded-full transition-all duration-200 
              cursor-pointer ease-in-out hover:bg-gray-800/50">
                <RxCross2 className="h-4 w-4 text-white"/>
              </div>
            </h1>
          </div>

        </div>

        <Header />
        <SearchBar />
        <CarouselComponent websiteDetails={websiteDetails} />
        <ClientsAndProducts  websiteDetails={websiteDetails} />
        <Trending />
        <Categories />
        <Testimonials websiteDetails={websiteDetails} />
        <YoutubeChannel websiteDetails={websiteDetails} />
        <Achievements websiteDetails={websiteDetails} />
        <Footer websiteDetails={websiteDetails} />
        
        <a href="https://wa.me/+918220373777" target="_blank">
          <div className="fixed bottom-4 right-4 cursor-pointer hover:scale-[105%] transition-all 
          duration-100 ease-in-out bg-[#59b512] rounded-full h-12 w-12 p-2 flex items-center justify-center z-50">
            <IoLogoWhatsapp className="h-8 w-8 text-white"/>
          </div>
        </a>
    </div>
  );
}

export default App;
