import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import sprite from "../sprite.svg";
import {useState,useEffect} from 'react';    
import { RiArrowRightWideLine,RiArrowLeftWideLine } from "react-icons/ri";

export default function CarouselComponent({websiteDetails}) {
  
  const [images,setImages] = useState([]);

  useEffect(()=>{
    if(websiteDetails){
      setImages(websiteDetails?.carousalImages);
    }
  },[websiteDetails])




  return (
    <div className="box relative">
      {
        images?.length > 0 &&
        <Carousel
          useKeyboardArrows={true}
          className='carousel'
          showIndicators={true}
          autoplay={true}
          interval={2000}
          transitionTime={500}
          showStatus={false}
          infiniteLoop={true}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              hasNext && (
                <button className="absolute top-0 bottom-0 m-auto md:h-12 h-8 md:w-12 w-8 opacity-[70%] hover:opacity-[100%] flex transition-all 
                duration-200 ease-in-out items-center 
                justify-center md:right-7 right-2 p-2 rounded-full hover:bg-black/50 z-50" onClick={clickHandler}>
                  <RiArrowRightWideLine className="text-white h-8 w-8"/>
                </button>
              )
            );
          }}
          renderArrowPrev={(clickHandler, hasNext) => {
            return (
              hasNext && (
                <button className="absolute top-0 bottom-0 m-auto md:h-12 h-8 md:w-12 w-8 opacity-[70%] hover:opacity-[100%] flex transition-all 
                duration-200 ease-in-out items-center 
                justify-center md:left-7 left-2 p-2 rounded-full hover:bg-black/50 z-50" onClick={clickHandler}>
                  <RiArrowLeftWideLine className="text-white h-8 w-8"/>
                </button>
              )
            );
          }}
          renderIndicator={(clickHandler, isSelected, index) => {
            return (
              <div
                className={`h-3 w-3 border-[1px] inline-block z-50 relative transition-all 
                duration-200 sm:mr-[1.4rem] mr-[.8rem] border-black mx-auto p-[2px] 
                border-solid bg-white rounded-full shadow-lg shadow-blue-500 
                hover:scale-[110%] `}
                onClick={clickHandler}
                key={index}
                role="button"
              >
              	<div className={`${isSelected ? 'scale-[100%] bg-black animate-pulse' : 'scale-[0%]'} transition-all 
              	duration-200 ease-in-out h-full w-full rounded-full`}/>
              </div>
            );
          }}
          showThumbs={false}
          swipeable={true} 
        >
          {images?.map((image, index) => (
            <div className="w-full h-full">
              <img alt="sample_file" src={image?.image} key={index} className="w-full aspect-[16/9] 
              max-h-[520px] object-cover object-center" />
            </div>
          ))}
        </Carousel>
      }
    </div>
  );
}
