import React from 'react';

const Shipping = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Shipping and Delivery</h1>
      <p>Thank you for visiting us and shopping at DHANA FOOD PRODUCTS. Following are the terms and conditions that constitute our Shipping Policy.</p>
      
      <h2 className="text-xl font-bold mt-6 mb-2">Shipment Processing Time</h2>
      <p><b>All orders are processed within 1-3 business days</b> from the date of order placement. Orders are not shipped or delivered on weekends and holidays.</p>
      
      <h2 className="text-xl font-bold mt-6 mb-2">Delivery Time</h2>
      <p><b>All orders are delivered within 3-5 business days</b>. Orders are not delivered on weekends and holidays.</p>
      
      <h2 className="text-xl font-bold mt-6 mb-2">Domestic Shipping Policy</h2>
      <p className="mt-4">If we are experiencing a high volume of orders, shipments may get delayed by a few days. Please allow additional days in transit for delivery. If there will be a significant delay in shipment of your order, we will contact you via email or telephone.</p>
      <p className="mt-4 font-bold">Shipment to P.O. boxes or APO/FPO Addresses</p>
      <p>Delivery delays can occasionally occur.</p>
      
      <h3 className="text-lg font-bold mt-4 mb-2">Shipping Fees</h3>
      <p>We offer shipping at the following rates:</p>
      <p>All times and dates given for delivery of the products are given in good faith but are estimates only.</p>
      
      <h3 className="text-lg font-bold mt-4 mb-2">Customs, Duties and Taxes</h3>
      <p>Dhana food products is not responsible for any customs and taxes applied to your order. All fees imposed during or after shipping are the responsibility of the customer (tariffs, taxes, etc.).</p>
      
      <h3 className="text-lg font-bold mt-4 mb-2">Damages</h3>
      <p>Dhana food products is not liable for any products damaged or lost during shipping. If you receive your order damaged, please contact the shipment carrier to file a claim. Dhana food products will provide all necessary assistance to process your claim smoothly.</p>
      <p className="mt-4">Please save all packaging materials and damaged goods before filing a claim.</p>
      
      <h2 className="text-xl font-bold mt-6 mb-2">International Shipping Policy</h2>
      <p>We ship outside the territory of India. Flight charges as per actual may apply. Please contact us at <a href="mailto:dhanasfoodproduct@gmail.com" className="text-blue-500">dhanasfoodproduct@gmail.com</a> for further information.</p>
    </div>
  );
};

export default Shipping;
