import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {motion} from 'framer-motion'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { HiUserCircle } from "react-icons/hi";
import { ImQuotesLeft } from "react-icons/im";


import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export default function Testimonials({websiteDetails}) {
	
	const [testimonials,setTesimonials] = useState([
	{
		name:'thejas hari',
		feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		image:'',
		designation:"CEO of Thyrocare"
	},
	{
		name:'Vishwesh',
		feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		image:'/assets/4.jpeg',
		designation:"CEO of Nginix"
	},
	{
		name:'Ashish',
		feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		image:'/assets/3.jpeg',
		designation:"CEO of Nginix"
	},
	{
		name:'Siva',
		feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		image:'',
		designation:"CEO of Thyrocare"
	},
	{
		name:'Vishnu',
		feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		image:'/assets/1.jpeg',
		designation:"CEO of Nginix"
	},
	{
		name:'Ragul',
		feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		image:'/assets/3.jpeg',
		designation:"CEO of Nginix"
	},
	{
		name:'TRS Vishnu',
		feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		image:'',
		designation:"CEO of Thyrocare"
	},
	{
		name:'Vicky raghavan',
		feedback:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		image:'/assets/2.jpeg',
		designation:"CEO of Nginix"
	},
	])
	const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());

	function getSlidesPerView() {
	    if (window.innerWidth >= 768) {
	      return 4;
	    } else if (window.innerWidth >= 640) {
	      return 3;
	    } else if (window.innerWidth >= 480) {
	      return 2;
	    } else {
	      return 1;
	    }
	}


	useEffect(() => {
	    const handleResize = () => {
	      setSlidesPerView(getSlidesPerView());
	    };

	    window.addEventListener('resize', handleResize);

	    // Cleanup event listener on component unmount
	    return () => {
	      window.removeEventListener('resize', handleResize);
	    };
	}, []);

	useEffect(()=>{
		if(websiteDetails?.testimonials?.length > 0){
			setTesimonials(websiteDetails?.testimonials);
		}
	},[websiteDetails])

	return (
		<main className="max-w-6xl mx-auto xl:px-0 sm:mt-3 mt-8 pb-0 px-5">
			<div className="flex items-center gap-2 justify-between">
				<h1 className="text-black w-full text-center text-3xl font-semibold">
					Our Testimonials
				</h1>

			</div>
			 <Swiper
		        slidesPerView={slidesPerView}
		        spaceBetween={12}
		        
		        autoplay={{
		          delay: 3000,
		          disableOnInteraction: false,
		        }}
		        modules={[Autoplay, Pagination, Navigation]}
		        className="mt-5 md:h-auto h-[300px]"
		      >
		      	{
		      		testimonials?.map((testimonial,j)=>(
		      			<SwiperSlide>
		      				<motion.div 
							initial={{scale:0}}
							transition={{ duration: 0.7 }}
							whileInView={{ scale:1 }}
							viewport={{ once: true }}
		      				key={j} className="border-[1px] border-gray-400 p-2 relative rounded-2xl px-4">
		      					<div className="absolute top-4 right-4">
		      						<ImQuotesLeft className="text-gray-800 h-5 w-5"/>
		      					</div>
		      					<div className="flex items-center gap-2 py-2 pb-3 border-b-[1px] border-gray-300">
		      						{
		      							testimonial?.image ?
			      						<img src={testimonial?.image} alt=""
			      						className="h-10 w-10 rounded-full shadow-md"/>
			      						:
			      						<div className="h-full flex items-center justify-center" >
			      							<HiUserCircle className="h-[42px] w-[42px] text-gray-600"/>
			      						</div>
		      						}
		      						<div className="flex flex-col w-full">
			      						<p className="text-lg truncate w-[80%] font-semibold text-gray-900" >
			      							{testimonial?.name}
			      						</p>
			      						<p className="sm:text-xs text-sm text-gray-700" >
			      							{testimonial?.designation}
			      						</p>
		      						</div>
		      					</div>

		      					<div className="py-2 pt-3">
		      						<p className="text-gray-800 text-sm">
		      							{testimonial?.testimonial}
		      						</p>
		      					</div>
		      				</motion.div>
		      			</SwiperSlide>
		      		))
		      	}
		      </Swiper>

		</main>
	)
}