import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Cart from './pages/Cart';
import Login from './pages/Login';
import Orders from './pages/Orders';
import Product from './pages/Product';
import Products from './pages/Products';
import Settings from './pages/Settings';
import Refund from './pages/Refund';
import Shipping from './pages/Shipping';
import Privacy from './pages/Privacy';
import TermsAndConditions from './pages/TermsAndConditions';
import { RecoilRoot } from "recoil";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Orders" element={<Orders />} />
          <Route path="/Product" element={<Product />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/Refund-Policy" element={<Refund />} />
          <Route path="/Shipping-Delivery" element={<Shipping />} />
          <Route path="/Privacy-Policy" element={<Privacy />} />
          <Route path="/Terms-Conditions" element={<TermsAndConditions />} />
          
          
        </Routes>
      </Router>
    </RecoilRoot>
  </React.StrictMode>
);
