
import { LuBox } from "react-icons/lu";
import { LiaUsersSolid } from "react-icons/lia";
import ProductsSold from './ProductsSold'

export default function ClientsAndProducts({websiteDetails}) {
	

	return (
		<div className="flex md:flex-row flex-col 
		items-center md:w-[80%] py-[40px] justify-around gap-10 w-full mx-auto">
			{
				websiteDetails?.productsSold &&
				<ProductsSold title="Products Sold" quantity={websiteDetails?.productsSold} 
				icon={<LuBox className="md:h-[80px] h-[70px] md:w-[80px] w-[70px] text-black"/>} />
			}
			{
				websiteDetails?.happyCustomers &&
				<ProductsSold title="Happy Customers" quantity={websiteDetails?.happyCustomers} 
				icon={<LiaUsersSolid className="md:h-[80px] h-[70px] md:w-[80px] w-[70px] text-black"/>} />
			}
		</div>
	)
}