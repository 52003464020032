
import {useState,useEffect} from 'react';

export default function ProductsCategoryCard({
	category,k,selectedCategories,setSelectedCategories,
	setBestSelling,setFavorites,setSearchQuery
}) {
	const [selected,setSelected] = useState(false);

	useEffect(()=>{
		if(selectedCategories?.includes(category?.categoryId)){
			setSelected(true);
		}else{
			setSelected(false);
		}
	},[selectedCategories,category])

	return (
		<div onClick={()=>{
			setBestSelling(false);
			setFavorites(false);
			setSearchQuery('');
			if(selectedCategories?.includes(category?.categoryId)){
				let cats = [...selectedCategories];
				const idx = cats?.findIndex((cat)=>{
					if(cat === category?.categoryId) return true
					return false
				})
				if(idx > -1){
					cats.splice(idx,1);
					setSelectedCategories(cats);
				}
				setSelected(false);
			}else{
				setSelectedCategories([...selectedCategories,category?.categoryId])
				setSelected(true);
			}
		}} className="px-1 cursor-pointer py-1 flex items-center gap-2">
			<input type="checkbox" checked={selected}
			onChange={(e)=>{
				if(e.target.checked){
					setSelectedCategories([...selectedCategories,category?.categoryId]);
					setSelected(true);
				}else{
					let cats = [...selectedCategories];
					const idx = cats?.findIndex((cat)=>{
						if(cat === category?.categoryId) return true
						return false
					})
					if(idx > -1){
						cats.splice(idx,1);
						setSelectedCategories(cats);
					}
					setSelected(false);
				}
			}}
			/>
			<p className="text-md font-semibold text-gray-900">
				{category?.name}
			</p>
		</div>
	)
}