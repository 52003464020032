
import Header from '../components/Header';
import SearchBar from '../components/SearchBar';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useRecoilState} from 'recoil';
import {useState,useEffect} from 'react';
import {currentUserState} from '../atoms/userAtom';
import {fetchOrdersRoute,login} from '../utils/ApiRoutes';
import OrderCard from '../components/OrderCard'
import axios from 'axios';
import './Orders.css';

export default function Orders() {
	const [currentUser,setCurrentUser] = useRecoilState(currentUserState);
	const [orders,setOrders] = useState([]);
	const [inactiveOrders,setInactiveOrders] = useState([]);
	const [loading,setLoading] = useState(true);

	const toastOptions = {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
		transition: Bounce,
	}

	const fetchOrders = async() => {
		const {data} = await axios.post(fetchOrdersRoute,{
			orders:currentUser?.orders
		})
		if(data?.status){
			let activeOrders = data?.order?.filter((ord)=>{
				if(ord.deliveryStatus.toLowerCase() !== 'delivered'){
					return true
				}
				return false
			})
			let inActive = data?.order?.filter((ord)=>{
				if(ord.deliveryStatus.toLowerCase() === 'delivered'){
					return true
				}
				return false
			})
			setOrders(activeOrders.reverse());
			setInactiveOrders(inActive.reverse());
			setLoading(false);
		}else{
			setLoading(false);
		}
	};

	const checkAuth = async() => {
	    if(!currentUser && localStorage.getItem("Dhana-Foods-Auth")){
	      const localData = localStorage.getItem("Dhana-Foods-Auth");
	      const parsedData = JSON.parse(localData);
	      const {data} = await axios.post(login,parsedData);
	      if(data?.status) {
	        setCurrentUser(data?.user);
	      }
	    }
	}

	useEffect(()=>{
		if(currentUser.orders?.length > 0){
			fetchOrders();
			setLoading(true);
		}else{
			setLoading(false)
		}
	},[currentUser])

	useEffect(()=>{checkAuth()},[])

	return (
		<div className="bg-gray-50 overflow-y-auto h-[100vh] w-full">
	        <Header />
        	<SearchBar />
    	  	<ToastContainer />

    	  	<div className="w-full md:px-7 flex md:gap-7 gap-5 md:flex-row flex-col px-2 py-5">
    	  		<div className="md:w-[50%] w-full bg-white shadow-md md:px-4 px-2 py-6">
    	  			<h1 className="text-xl text-black font-semibold">
    	  				Your Orders
    	  			</h1>
    	  			<div className="h-[1px] w-full my-4 bg-gray-300 mx-auto"/>
    	  			{
    	  				loading ?
    	  				<div className="w-full flex items-center justify-center h-[200px]">
    	  					<div class="loader">
							    <svg viewBox="0 0 80 80">
							        <circle id="test" cx="40" cy="40" r="32"></circle>
							    </svg>
							</div>

							<div class="loader triangle">
							    <svg viewBox="0 0 86 80">
							        <polygon points="43 8 79 72 7 72"></polygon>
							    </svg>
							</div>

							<div class="loader">
							    <svg viewBox="0 0 80 80">
							        <rect x="8" y="8" width="64" height="64"></rect>
							    </svg>
							</div>
    	  				</div>
    	  				:
    	  				orders?.length > 0 ?
    	  				orders?.map((order,k)=>(
    	  					<OrderCard toast={toast} toastOptions={toastOptions} order={order} key={k} k={k}
    	  					/>
    	  				))
    	  				:
    	  				<div className="flex items-center flex-col justify-center">
    	  					<img src="https://cdni.iconscout.com/illustration/premium/thumb/man-receiving-canceled-orders-back-4438793-3718471.png?f=webp"
    	  					alt="" className="h-[200px]"
    	  					/>
    	  					<p className="text-md font-semibold text-gray-600">
    	  						No Orders Active 
    	  						<span className="text-blue-500 hover:text-blue-600 cursor-pointer"> Shop Now!</span>
    	  					</p>
    	  				</div>
    	  			}


    	  		</div>
    	  		<div className="md:w-[50%] w-full">
	    	  		<div className="w-full bg-white shadow-md px-4 py-6">
	    	  			<h1 className="text-xl text-black font-semibold">
	    	  				Your Orders History
	    	  			</h1>
	    	  			<div className="h-[1px] w-full my-4 bg-gray-300 mx-auto"/>
	    	  			{
	    	  				loading ?
	    	  				<div className="w-full flex items-center justify-center h-[200px]">
	    	  					<div class="loader">
								    <svg viewBox="0 0 80 80">
								        <circle id="test" cx="40" cy="40" r="32"></circle>
								    </svg>
								</div>

								<div class="loader triangle">
								    <svg viewBox="0 0 86 80">
								        <polygon points="43 8 79 72 7 72"></polygon>
								    </svg>
								</div>

								<div class="loader">
								    <svg viewBox="0 0 80 80">
								        <rect x="8" y="8" width="64" height="64"></rect>
								    </svg>
								</div>
	    	  				</div>
	    	  				:
	    	  				inactiveOrders?.length > 0 ?
	    	  				inactiveOrders?.map((order,k)=>(
	    	  					<OrderCard toast={toast} toastOptions={toastOptions} order={order} key={k} k={k}
	    	  					/>
	    	  				))
	    	  				:
	    	  				<div className="flex items-center flex-col justify-center">
	    	  					<img src="https://cdni.iconscout.com/illustration/premium/thumb/man-finding-nothing-in-order-4006350-3309936.png"
	    	  					alt="" className="h-[200px]"
	    	  					/>
	    	  					<p className="text-md font-semibold text-gray-600">
	    	  						No Orders History
	    	  						<span className="text-blue-500 hover:text-blue-600 cursor-pointer"> Shop Now!</span>
	    	  					</p>
	    	  				</div>
	    	  			}
	    	  			


	    	  		</div>
    	  		</div>


    	  	</div>

    	</div>
	)
}