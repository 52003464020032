import { useState, useEffect, useRef } from 'react';


export default function ProductsSold({
	title,
	quantity,
	icon
}) {
	const [productsSold, setProductsSold] = useState(0);
 	const componentRef = useRef(null);
    const [animationComplete, setAnimationComplete] = useState(false);
 	
    useEffect(() => {
	    if (!animationComplete) {
	      const observer = new IntersectionObserver(
	        (entries) => {
	          entries.forEach((entry) => {
	            if (entry.isIntersecting) {
	              const startTime = Date.now();
	              const duration = 3500; 

	              const updateExperience = () => {
	                const currentTime = Date.now();
	                const elapsedTime = currentTime - startTime;

	                if (elapsedTime < duration) {
	                  const progress = elapsedTime / duration;
	                  const updatedExperience = Math.min(progress * Number(quantity), Number(quantity));

	                  setProductsSold(updatedExperience);

	                  requestAnimationFrame(updateExperience);
	                } else {
	                  setProductsSold(Number(quantity));
	                  setAnimationComplete(true);
	                }
	              };
	              updateExperience();
	              observer.disconnect(); 
	            }
	          });
	        },
	        { threshold: 0.5 } 
	      );

	      observer.observe(componentRef.current);

	      return () => {
	        observer.disconnect();
	      };
	    }
	  }, [animationComplete]);

	return (
		<div ref={componentRef} className="flex flex-col items-center gap-1">
			{icon}
			<p className="text-xl font-semibold m-0 p-0" >{productsSold.toFixed(0)}+</p>
			<p className="text-md" >{title}</p>
		</div>
	)
}