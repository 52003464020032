import { HiOutlineArrowSmRight, HiOutlineArrowRight } from "react-icons/hi";
import ProductCardHover from './ProductCardHover';
import {useRecoilState} from 'recoil';
import {AiOutlineArrowRight} from 'react-icons/ai';
import {currentUserState,selectedCategoriesState} from '../atoms/userAtom';
import {useState,useEffect} from 'react';
import axios from 'axios';
import {fetchProducts} from '../utils/ApiRoutes';
import {useNavigate} from 'react-router-dom';

export default function Trending() {
	const [currentUser,setCurrentUser] = useRecoilState(currentUserState);
	const [selectedCategories,setSelectedCategories] = useRecoilState(selectedCategoriesState);
	const navigate = useNavigate();
	const [products,setProducts] = useState([]);

	const fetchTrendProducts = async() => {
		const {data} = await axios.post(fetchProducts,{bestSelling:true});
		if(data?.status){
			setProducts(data?.product);
		}else{
			setProducts([]);
		}
	}

	useEffect(()=>{
		fetchTrendProducts()
	},[])

	return (
		<main className="max-w-6xl mx-auto xl:px-0 px-5">
			<div className="flex items-center md:gap-5 gap-4">
				<h1 className="text-black text-xl font-semibold">
					Trending Products
				</h1>
				<div onClick={()=>{
					navigate("/Products?bestSelling=true");
					setSelectedCategories([]);
				}} className="p-1 bg-[#59b512] rounded-full cursor-pointer hover:scale-[105%] transition-all 
				duration-200 ease-in-out flex items-center justify-center">
					<HiOutlineArrowSmRight className="h-5 w-5 text-white"/>
				</div>
			</div>
			<div className="flex mt-5 mb-10 grid xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 sm:gap-5 gap-3">
				{
					products?.map((product,i)=>(
						<ProductCardHover currentUser={currentUser} product={product} 
						i={i} key={i} setCurrentUser={setCurrentUser} />
					))
				}
			</div>
			<div className="w-full flex sm:mb-5 mb-9 justify-center">
		  		<button onClick={()=>{
					navigate("/Products");
					setSelectedCategories([]);
				}} className="flex items-center bg-[#59b512] rounded-lg px-5 py-2 gap-2 hover:gap-5 
				transition-all duration-200 ease-in-out text-white sm:text-lg text-md">
		  			Show All Products
		  			<HiOutlineArrowRight className="sm:h-5 sm:w-5 h-4 w-4"/>
		  		</button>
		  	</div>

		</main>
	)
}