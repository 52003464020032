import React, { useEffect } from 'react';

const PhoneSignInButton = ({
  setNumberVerified,toastOptions,toast
}) => {
  useEffect(() => {
    // Load the phone.email sign-in button script
    const script = document.createElement('script');
    script.src = "https://www.phone.email/sign_in_button_v1.js";
    script.async = true;
    document.body.appendChild(script);

    // Define the phoneEmailListener function
    window.phoneEmailListener = function(userObj) {
      const user_json_url = userObj.user_json_url;  // URL: https://user.phone.email/user_abcxd123fgbfg43454.json
      const user_country_code = userObj.user_country_code;
      const user_phone_number = userObj.user_phone_number;
      toast.success("Phone Number Verified Successfully",toastOptions);

      setNumberVerified(true);
      // You can submit your form here or redirect user to post-login dashboard page
      // Send user_json_url to your backend to retrieve user info (i.e. country code and phone number) from this URL.
    };

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="pe_signin_button" data-client-id="12479580685757506696">
      {/* The sign-in button will be inserted here by the script */}
    </div>
  );
};

export default PhoneSignInButton;
