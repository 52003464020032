import { IoCartOutline } from "react-icons/io5";
import { FiMenu, FiHeart } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TbSearch } from "react-icons/tb";
import { AiFillProduct } from "react-icons/ai";
import { IoIosSettings } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import { RxCross2 } from 'react-icons/rx';
import { HiOutlineArrowRight } from 'react-icons/hi';
import {useState,useEffect} from 'react';
import {useRecoilState} from 'recoil';
import axios from 'axios';
import {getProducts} from '../utils/ApiRoutes';
import {currentUserState,openSideBarState,currentProductIdState,searchQueryState} from '../atoms/userAtom';
import {useNavigate} from 'react-router-dom';

export default function SearchBar({notSticky}) {
	const [openSettingsBar,setOpenSettingsBar] = useState(false);
	const [currentUser,setCurrentUser] = useRecoilState(currentUserState);
	const [openSideBar,setOpenSideBar] = useRecoilState(openSideBarState);
	const [currentProductId,setCurrentProductId] = useRecoilState(currentProductIdState)
	const [searchResult,setSearchResult] = useState([]);
	const [showSearchResult,setShowSearchResult] = useState(false);
	const [searchQuery,setSearchQuery] = useRecoilState(searchQueryState);
	const navigation = useNavigate();

	const logOutFunc = async() => {
		setCurrentUser('');
		await localStorage.removeItem("Dhana-Foods-Auth");
		navigation('/login');
	};

	const searchProduct = async(title) => {
		const {data} = await axios.post(getProducts,{
			title
		});
		if (data?.status) {
			setSearchResult(data?.product)
		}else{
			setSearchResult([]);
		}
	}

	useEffect(() => {
	    const handler = setTimeout(() => {
	      if (searchQuery.length > 0) {
	        setShowSearchResult(true);
	        searchProduct(searchQuery);
	      } else {
	        setShowSearchResult(false);
	      }
	    }, 300);

	    return () => {
	      clearTimeout(handler);
	    };
  	}, [searchQuery]);

	return (
		<header className={`md:px-7 px-3 bg-gray-100 border-b-[1px] 
		gap-3 border-gray-300 flex ${notSticky ? '' : 'sticky'} top-0 z-50 items-center justify-between py-2`} >
			<div onClick={()=>{setOpenSideBar(true)}} className="p-2 cursor-pointer bg-[#59b512] rounded-lg">
				<FiMenu className="h-6 w-6 text-white"/>
			</div>

			<div className="rounded-full border-[1px] md:w-[50%] w-[60%]
			border-gray-400 flex items-center relative">
				<div className="flex w-full rounded-l-full md:rounded-r-[0px] rounded-r-full bg-white">
					<div onClick={()=>{
						if (showSearchResult) {
							setShowSearchResult(false)
							if(notSticky){
								setSearchQuery('');
							}
						}else{
							if(searchQuery?.length > 0){
								setShowSearchResult(true);
							}
						}
					}} className="md:px-6 cursor-pointer hover:text-blue-500 text-gray-600 transition-all 
					duration-100 ease-in-out md:px-3 px-2 md:py-[5px] py-2 border-r-[1px] border-gray-400 flex items-center gap-2">
						<p className="text-md md:block hidden whitespace-nowrap">Show Results</p>
						<MdKeyboardArrowDown className={`h-4 w-4 ${showSearchResult ? 'rotate-180' : 'rotate-0'} transition-all duration-200 ease-in-out `}/>
					</div>
					<div className="md:px-4 px-2 md:py-[5px] py-2 flex items-center w-full gap-2">
						<input type="text" value={searchQuery} placeholder="Search your products..." onChange={(e)=>{
							setSearchQuery(e.target.value);
						}}
						className="outline-none bg-transparent md:w-full text-sm text-black placeholder:text-gray-700"
						/>
					</div>
				</div>
				<div className="bg-[#59b512] rounded-r-full border-l-[1px] border-gray-400 md:block 
				hidden h-full py-[5px] px-3 cursor-pointer">
					<TbSearch className="text-white h-6 w-6"/>
				</div>	

				<div className={`w-full px-5 ${showSearchResult && !notSticky ? 'absolute' : 'hidden'} py-2 bg-white top-10 
				rounded-lg left-0 border-[1px] border-gray-400 gap-2 flex flex-col`}>
					{
						searchResult?.length > 0 ?
						searchResult?.map((product,k)=>(
							<div onClick={()=>{
								navigation(`/Product?productId=${product?.productId}`);
								setCurrentProductId(product?.productId);
								setSearchQuery('');
								setSearchResult([]);
							}} className="w-full cursor-pointer flex group items-center justify-between">
								<p className="text-md font-semibold text-gray-900 group-hover:text-blue-500 
								transition-all duration-200 ease-in-out">{product?.title}</p>

								<HiOutlineArrowRight className="h-4 w-4 text-gray-800 group-hover:text-blue-500 
								transition-all duration-200 ease-in-out"/>
							</div>
						))
						:
						<div className="w-full px-4 py-1">
							<h1 className="text-md text-center text-gray-500 font-semibold">
								-- Not Found --
							</h1>
						</div>
					}
					{
						searchResult?.length > 0 &&
						<div onClick={()=>{
							navigation("/Products");
						}} className="px-4 w-full cursor-pointer group">
							<p className="text-blue-500 justify-center group-hover:text-blue-700 gap-2 
							group-hover:gap-4 flex items-center transition-all duration-200 ease-in-out">
								Show More
								<HiOutlineArrowRight className="h-4 w-4"/>
							</p>
						</div>
					}
				</div>

			</div>

			<div className="flex items-center relative md:gap-3 gap-2">
				<div className={`absolute bg-gray-50 border-[1px] border-gray-400 
				 right-0 ${openSettingsBar ? 'md:top-[45px] top-[48px]' : '-top-[700%]'} 
				rounded-lg overflow-hidden ${notSticky && 'z-50'} transition-all duration-200 ease-in-out`}>
					<div onClick={()=>{
						navigation('/Orders');
					}} className="flex items-center cursor-pointer text-black px-2 py-1 
					hover:bg-gray-200 transition-all whitespace-nowrap duration-200 ease-in-out gap-2">
						<AiFillProduct className="h-5 w-5"/>
						My Orders
					</div>
					<div onClick={()=>{
						navigation('/Settings');
					}} className="flex items-center cursor-pointer text-black px-2 py-1 
					hover:bg-gray-200 group transition-all duration-200 ease-in-out gap-2">
						<IoIosSettings className="h-5 group-hover:rotate-180 transition-all 
						duration-200 ease-in-out w-5"/>
						Settings
					</div>
					<div onClick={logOutFunc}
					className="flex items-center cursor-pointer text-black px-2 py-1 
					hover:bg-gray-200 hover:text-red-500 transition-all duration-200 ease-in-out gap-2">
						<IoLogOutOutline className="h-5 transition-all 
						duration-200 ease-in-out w-5"/>
						Log out
					</div>
				</div>	
				<div onClick={()=>navigation("/Products?showFavorites=true")} className="p-2 hidden md:block cursor-pointer bg-[#59b512] rounded-lg">
					{
						currentUser?.favProducts?.length > 0 &&
						<div className="absolute bg-red-500 p-1 h-4 w-4 flex items-center 
						justify-center rounded-full -top-[6px] -left-[6px]">
							<p className="text-white text-[10px]">{currentUser?.favProducts?.length}</p>
						</div>
					}
					<FiHeart className="h-4 w-4 text-white"/>
				</div>
				<div onClick={()=>navigation('/Cart')} className="p-2 relative cursor-pointer bg-[#59b512] rounded-lg">
					{
						currentUser?.cart?.length > 0 &&
						<div className="absolute bg-red-500 p-1 h-4 w-4 flex items-center 
						justify-center rounded-full -top-[6px] -left-[6px]">
							<p className="text-white text-[10px]">{currentUser?.cart?.length}</p>
						</div>
					}
					<IoCartOutline className="md:h-4 h-5 md:w-4 w-5 text-white"/>
				</div>
				<div onClick={()=>{
					if(currentUser) {
						setOpenSettingsBar(!openSettingsBar)
					}else{
						navigation('/login');
					}
				}} 
				className="h-8 w-8 cursor-pointer bg-[#59b512] rounded-full overflow-hidden">
					<img src={currentUser?.image || "/assets/1.jpeg"} className="h-full w-full"/>
				</div>
			</div>

			<div className={`fixed ${openSideBar ? 'left-0' : '-left-[100%]'} h-full 
			w-full z-[60] top-0 transition-all duration-200 ease-in-out`}>
				<div onClick={()=>{setOpenSideBar(false)}} className={`absolute ${openSideBar ? 'h-full w-full' :  ''} top-0 left-0 bg-black/30`}>
				</div>
				<div className="relative bg-white sm:w-[250px] w-full h-full md:px-7 px-4 py-5">
					<RxCross2 onClick={()=>setOpenSideBar(false)} 
					className="h-7 w-7 absolute sm:hidden right-5 top-5 text-black" />
					<div className={`w-full transition-all duration-300 ease-in-out md:mt-0 mt-4 md:px-3 px-5`}>
						<img onClick={()=>{navigation('/'); setOpenSideBar(false)}} src="https://ik.imagekit.io/d3kzbpbila/thejashari_HkNJJQp9gd" alt="" 
						className="sm:w-full w-[70%] mx-auto rounded-lg"/>
					</div>
					<div className="w-full flex flex-col gap-2 mt-5">
						<div onClick={()=>{navigation('/'); setOpenSideBar(false)}} className="px-2 py-2 rounded-lg cursor-pointer group transition-all duration-200 ease-in-out hover:bg-[#59b512]">
							<p className="text-gray-800 text-lg font-semibold text-center text-black group-hover:text-white transition-all duration-200 ease-in-out" >Home</p>
						</div>
						<div onClick={()=>{navigation('/Products'); setOpenSideBar(false)}} className="px-2 py-2 rounded-lg cursor-pointer group transition-all duration-200 ease-in-out hover:bg-[#59b512]">
							<p className="text-gray-800 text-lg font-semibold text-center text-black 
							group-hover:text-white transition-all duration-200 ease-in-out" >Shop Products</p>
						</div>
						<div onClick={()=>{navigation('/Cart'); setOpenSideBar(false)}} className="px-2 py-2 rounded-lg cursor-pointer group transition-all duration-200 ease-in-out hover:bg-[#59b512]">
							<p className="text-gray-800 text-lg font-semibold text-center text-black 
							group-hover:text-white transition-all duration-200 ease-in-out" >Cart</p>
						</div>
						<div onClick={()=>{navigation('/Orders'); setOpenSideBar(false)}} className="px-2 py-2 rounded-lg cursor-pointer group transition-all duration-200 ease-in-out hover:bg-[#59b512]">
							<p className="text-gray-800 text-lg font-semibold text-center text-black 
							group-hover:text-white transition-all duration-200 ease-in-out" >Orders</p>
						</div>
						<a href="https://wa.me/+918220373777" target="_blank">
						<div onClick={()=>setOpenSideBar(false)} className="px-2 py-2 rounded-lg cursor-pointer group transition-all duration-200 ease-in-out hover:bg-[#59b512]">
							<p className="text-gray-800 text-lg font-semibold text-center text-black 
							group-hover:text-white transition-all duration-200 ease-in-out" >Contact Support</p>
						</div>
						</a>
						<div onClick={()=>{navigation('/Settings'); setOpenSideBar(false)}} className="px-2 py-2 rounded-lg cursor-pointer group transition-all duration-200 ease-in-out hover:bg-[#59b512]">
							<p className="text-gray-800 text-lg font-semibold text-center text-black 
							group-hover:text-white transition-all duration-200 ease-in-out" >Settings</p>
						</div>
					</div>


				</div>
			</div>



		</header>
	)
}